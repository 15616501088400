import { createSlice } from "@reduxjs/toolkit";
import { markets } from "../../Helper/constant";

export const initialState = {
  accountType: "",
  is_sample_account: false,
  isDemoAccount: false,
  is_edit: false,
  parentId: null,
  edit_userData: [],
  basicInfo: {
    accountName: "",
    accountId: "",
    accountPassword: "1234",
    balance: "",
    openingBalance: 0,
    linkLedger: false,
    importUserSettings: "",
    defaultUser: "",
  },

  accountDetails: {
    allowNoOfAccountMasterAdmin: 0,
    allowNoOfAccountSuperAdmin: 0,
    allowNoOfAccountAdmin: 0,
    allowNoOfAccountMaster: 0,
    allowNoOfAccountBroker: 0,
    allowNoOfAccountDealer: "",
    allowNoOfAccountCustomer: "",
    isOnlyPositionSquareup: false,
    partnershipWith: "percentage",
    minimumBrokerageRequire: true,
    additionalInfo: {
      editTrade: "block",
      restoreAutoSquarUp: "block",
      acceptRejectedTrade: "block",
      ipAddressReport: "block",
      tvChannel: "block",
      clientDelete: "view",
      offlineTrade: "view",
      tradeDelete: "view",
      bfCfEdit: "view",
      ledgerDelete: "view",
      scriptBlocksList: "allow",
      valanDelete: "block",
      accountPointEntry: "allow",
      newClientAdd: "allow",
      additionalSharingInfo: "allow",
      scriptWiseSettings: "allow",
      broadcastMsg: "block",
      marginExamtion: "block",
      limitBetweenHighAndLow: "block",
      allowLimitOrder: "allow",
      allowQuantityTradingInfo: "block",
      allowShortSellInfo: "block",
    },
    orderBetweenHighLow: false,
    showDetailBill: false,
    allowBannedScript: false,
    showDepositeToCustomer: false,
    isAutoStopNewPosition: false,
    autoStopNewPosition: {
      profit: null,
      loss: null,
    },
    isApplyAutoSquareup: false,
    applyAutoSquareup: {
      profit: null,
      loss: null,
    },
    isM2MAlert: false,
    M2MAlert: {
      profit: null,
      loss: null,
    },
    isNewPositionSquareupTime: false,
    newPositionSquareupTime: { time: null, stringTime: null },
    isFreshLimitAllow: true,
    freshLimitAllow: "",
    remark: "",
  },
  parentValidation: {},
  //market access
  market_access: [],
  "NSE FUT": {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfLot: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  "NSE OPT": {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "fixed",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfLot: null,
      orderLimits: null,
      strikeRateMaxLot: null,
      allowShortSell: false,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  "NSE EQU": {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
      // deliveryType: "percentage",
      // intradayType: "percentage",
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfQuantity: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      orderLimits: null,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  MCX: {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "fixed",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfLot: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  "MCX OPT": {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "fixed",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfLot: null,
      orderLimits: null,
      strikeRateMaxLot: null,
      allowShortSell: false,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  FOREX: {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      totalNoOfLot: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  COMEX: {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  GLOBEX: {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
  CRYPTO: {
    isOnlyPositionSquareup: false,
    brokerageDetails: {
      brokerageType: "percentage",
      deliveryTotal: 0,
      deliveryBuy: 0,
      intradayBuy: 0,
      intradayTotal: 0,
      deliverySell: 0,
      intradaySell: 0,
      percentage: 0,
      remainingPercentage: 0,
    },
    additionalSharing: [],
    margin: {
      type: "value",
      delivery: 0,
      intraday: 0,
      total: 0,
      interestRate: 0,
    },
    scriptWiseSettingInfo: {
      minimumScriptRate: null,
      totalNoOfScripts: null,
      orderLimits: null,
      allowLimitOrder: true,
      allowQuantityTrading: false,
      selectedScript: false,
    },
    allScript: [],
    blockScript: [],
  },
};

export const userAccountSlice = createSlice({
  name: "userAccountSlice",
  initialState,
  reducers: {
    resetUserAccountSlice: () => ({ ...initialState }),
    setNewAccountInfo(state, action) {
      const { tab, view, index_key, parent_key, nested_key, key, obj, value } =
        action.payload;

      if (parent_key && nested_key && obj) {
        Object.keys(obj)?.forEach?.((_key) => {
          state[parent_key][nested_key][_key] = obj[_key];
        });
      } else if (parent_key && nested_key) {
        state[parent_key][nested_key][key] = value;
      } else if (parent_key) {
        const isRent = value === "rent";
        const isAdditionlInfo = key === "additionalInfo";

        if (isRent) {
          markets.forEach((el) => {
            if (state[el]) {
              state[el].brokerageDetails = {
                ...initialState?.[el]?.brokerageDetails,
              };
            }
          });
        }

        state[parent_key][key] = isAdditionlInfo
          ? { ...state[parent_key][key], ...value }
          : value;
      } else if (tab && view && key) {
        if (action?.payload?.hasOwnProperty("index_key")) {
          state[tab][view][index_key][key] = action.payload[key];
        } else if (action?.payload?.isObj) {
          Object.keys(action.payload?.isObj)?.forEach((key) => {
            state[tab][view][key] = action.payload?.isObj[key];
          });
        } else if (view === "allScript") {
          if (key === "add") {
            const id = state[tab][view]?.findIndex(
              (el) => el?.scriptName === obj?.scriptName
            );
            if (id === -1) {
              state[tab][view]?.push(obj);
            } else {
              state[tab][view][id] = obj;
            }
          } else {
            state[tab][view] = state[tab][view]?.filter(
              (el) => el.scriptName !== obj?.scriptName
            );
          }
        } else if (view === "isOnlyPositionSquareup") {
          state[tab][view] = value;
        } else {
          state[tab][view][key] = action.payload[key];
        }
      } else {
        Object.keys(action.payload)?.forEach((_key) => {
          state[_key] = action.payload[_key];
        });
      }
    },
    blockedScript: (state, action) => {
      const { tab, script, type } = action.payload;
      if (type === "add") {
        state[tab].blockScript?.push(script);
      } else {
        state[tab].blockScript = state[tab].blockScript?.filter(
          (el) => el !== script
        );
      }
    },
    setParentValidation: (state, action) => {
      state.parentValidation = action.payload;
    },
    resetSelectedMarket: (state) => {
      state.market_access = [];
    },
  },
});

export const {
  resetUserAccountSlice,
  setNewAccountInfo,
  blockedScript,
  setParentValidation,
  resetSelectedMarket,
} = userAccountSlice.actions;
export default userAccountSlice.reducer;
