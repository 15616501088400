import { Spin, notification } from "antd";
import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { styled } from "styled-components";
import { useEffect } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useState } from "react";
import MarketCart from "./MarketCart";
import dayjs from "dayjs";
import "./marketSetting.css";
import { markets, MarketSettingRow, StyleDiv } from "./style";

export const Container = styled.div`
  position: relative;
  opacity: ${(props) => (props.isloading === "true" ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isloading === "true" ? "none" : "auto")};
`;
export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
`;

function convertToTimeFormat(value) {
  if (value) {
    const minutes = parseInt(value, 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  }
}

function formatTimeToNSEFUT(timeString) {
  if (timeString) {
    const [hour, minute] = timeString?.split(":").map(Number);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "short",
    });
    const currentDay = currentDate.toLocaleString("default", {
      weekday: "short",
    });
    const formattedDate = `${currentDay} ${currentMonth} ${currentDate.getDate()} ${currentYear}`;

    const nseFUTDate = new Date(currentDate);
    nseFUTDate.setHours(hour);
    nseFUTDate.setMinutes(minute);
    nseFUTDate.setSeconds(1); // Setting seconds to 1

    return dayjs(
      `NSE FUT ${formattedDate} ${nseFUTDate.toLocaleTimeString()} `
    );
  }
}

let initialState = {
  "NSE FUT": {},
  "NSE OPT": {},
  "NSE EQU": {},
  MCX: {},
  "MCX OPT": {},
  FOREX: {},
  COMEX: {},
  GLOBEX: {},
  CRYPTO: {},
};

const MarketSetting = () => {
  // state
  const [value, setValue] = useState(initialState);
  const [loading, setLoading] = useState(false);

  // life cycle
  useEffect(() => {
    getMarketStatus();
  }, []);

  // api call
  const getMarketStatus = async () => {
    setLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getMarketStatus}get-market-status`
      );

      if (response.status === 200) {
        let data = response?.data?.data;

        let obj = { ...initialState };

        data?.forEach((el) => {
          let startTime = formatTimeToNSEFUT(el?.sessionStartTime);
          let endTime = formatTimeToNSEFUT(el?.sessionEndTime);

          let endBefore = formatTimeToNSEFUT(
            convertToTimeFormat(el?.limitTradeAllowance?.endBefore)
          );
          let startAfter = formatTimeToNSEFUT(
            convertToTimeFormat(el?.limitTradeAllowance?.startAfter)
          );
          // console.log("endBefore", endBefore, "startAfter");
          if (obj[el?.marketName]) {
            obj[el?.marketName] = {
              sessionEndTime: endTime,
              sessionStartTime: startTime,
              status: el?.status,
              endBefore: endBefore,
              startAfter: startAfter,
              isLimitTradeAllowed: el?.isLimitTradeAllowed,
            };
          }
        });

        setValue(obj);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container isloading={loading.toString()}>
      {loading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}
      <ThemeCard
        title="Market Setting"
        bordered={false}
        style={{ marginBlock: "3rem", width: "100%" }}
      >
        <MarketSettingRow className="largeScreenTitle" justify={"center"}>
          {/* <Col>Market Trade</Col>
          <Col span={3}>Limit Trade</Col> */}
          {/* <Col lg={{ offset: 6, span: 3 }} xs={{ offset: 0, span: 0 }}>
            Market Trade
          </Col>
          <Col span={3}>Limit Trade</Col> */}
        </MarketSettingRow>
        <StyleDiv>
          {markets.map((market, index) => (
            <MarketCart
              key={index}
              market={market.label}
              index={index}
              values={value?.[market.label]}
              getMarketStatus={getMarketStatus}
            />
          ))}
        </StyleDiv>
      </ThemeCard>
    </Container>
  );
};

export default MarketSetting;
