import { createSlice } from "@reduxjs/toolkit";

const createInitalStateForMarket = (market, intradayValan) => {
  return {
    marketName: market,
    userId: null,
    scriptName: null,
    intraday: {
      squareUpRate: "Quotation Rate",
      valanEndingSquareUpRate: intradayValan ? "Closing Rate" : null,
    },
    position: {
      expirySquareUpRateAndDayBefore: {
        rate: intradayValan ? null : "Quotation Rate",
        dayBefore: intradayValan ? null : 0,
      },
      newPositionStartBeforeDay: intradayValan ? null : 3,
      valanEndingSquareUpRate: "Closing Rate",
    },
  };
};

const initialState = {
  tab: "NSE FUT",
  "NSE FUT": createInitalStateForMarket("NSE FUT", false),
  "NSE OPT": createInitalStateForMarket("NSE OPT", false),
  "NSE EQU": createInitalStateForMarket("NSE EQU", true),
  MCX: createInitalStateForMarket("MCX", false),
  "MCX OPT": createInitalStateForMarket("MCX OPT", false),
  COMEX: createInitalStateForMarket("COMEX", false),
  FOREX: createInitalStateForMarket("FOREX", true),
  GLOBEX: createInitalStateForMarket("GLOBEX", true),
  CRYPTO: createInitalStateForMarket("CRYPTO", true),
};

const expirySettingSlice = createSlice({
  name: "expirySettingSlice",
  initialState,
  reducers: {
    setExprirySetting: (state, action) => {
      const { value, tab, nested_key, parent_key, key, obj } = action.payload;
      if (obj && tab) {
        state[tab] = obj;
      } else if (value && tab && nested_key && parent_key) {
        state[tab][parent_key][nested_key][key] = value;
      } else if (value && tab && key && parent_key) {
        state[tab][parent_key][key] = value;
      } else if (value && tab && key) {
        state[tab][key] = value;
      }
    },
    setUserIdInExpirySetting: (state, action) => {
      for (let key in initialState) {
        if (key !== "tab") {
          state[key]["userId"] = action.payload;
        }
      }
    },
    resetExpirySetting: (state) => ({ ...initialState }),
    setTabChange: (state, action) => {
      state["tab"] = action.payload;
    },
  },
});

export default expirySettingSlice.reducer;

export const {
  setExprirySetting,
  setUserIdInExpirySetting,
  resetExpirySetting,
  setTabChange,
} = expirySettingSlice.actions;
